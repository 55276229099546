@keyframes glowing {
    0%, 100% {
        text-shadow: 0 0 5px rgba(255, 0, 0, 0.7);
    }
    50% {
        text-shadow: 0 0 15px rgba(255, 0, 0, 1);
    }
}

.glowing-text {
    animation: glowing 3s ease-in-out infinite;
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}